<template>
  <AuthLayout :bgImage="require('@bd/assets/images/image-woman.jpg')">
    <template #navigation>
      <div class="d-none d-md-flex align-self-start">
        <Button
          @click="$router.push({ name: 'Dashboard' })"
          icon="pi pi-chevron-left"
          class="p-button-secondary p-button-circle"
        />
      </div>
    </template>
    <template #logo>
      <div class="text-center mt-auto">
        <img :src="require('@bd/assets/images/immoby-logo.svg')" />
      </div>
    </template>
    <template #main>
      <AuthLogin @login="onLogin" :userType="UserType.Buyer" />
    </template>
    <template #footer>
      <div
        class="d-flex justify-content-center align-self-center body-sm text-center"
      >
        <div>
          <p class="mb-4 body-lg">
            {{ t('create_account_3') }}
            <router-link
              class="p-link text-inherit-size text-decoration-underline"
              to="/registration"
            >
              {{ t('create_account_4') }}
            </router-link>
          </p>
          <span class="d-none d-md-block">
            <p>{{ t('sell_apartment') }}</p>
            <p>{{ t('contact_helpline') }}</p>
            <p>{{ helpline }}</p>
          </span>
        </div>
      </div>
    </template>
  </AuthLayout>
</template>

<script lang="ts">
import { AuthLogin, AuthLayout, UserType } from '@bd/components'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { default as common } from '@/config/common.json'

export default defineComponent({
  name: 'Login',
  components: { AuthLogin, AuthLayout },
  setup() {
    const { t } = useI18n()
    const { helpline } = common
    const router = useRouter()

    const onLogin = () => router.push({ path: '/' })

    return { t, helpline, onLogin, UserType }
  },
})
</script>

<style lang="scss" scoped></style>
