
import { AuthLogin, AuthLayout, UserType } from '@bd/components'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { default as common } from '@/config/common.json'

export default defineComponent({
  name: 'Login',
  components: { AuthLogin, AuthLayout },
  setup() {
    const { t } = useI18n()
    const { helpline } = common
    const router = useRouter()

    const onLogin = () => router.push({ path: '/' })

    return { t, helpline, onLogin, UserType }
  },
})
